<template>
    <b-card>
        <template v-slot:header>
            <h5>Terima Selisih Barang</h5>
        </template>
        <b-table bordered hover show-empty :busy="isLoading" :items="listSelisih" :fields="fields" responsive class="mb-3">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading data...</strong>
                </div>
            </template>
            <template v-slot:head(action)="row">
                <div class="text-right">
                    Aksi
                </div>
            </template>
            <template v-slot:cell(action)="row">
                <div class="text-right">
                    <b-button @click="openModalDetail(row.item)" size="sm" variant="pgiBtn">
                        Terima
                    </b-button>
                </div>
            </template>
            <template v-slot:cell(supplier)="row">

                <div>
                    <small>
                        <b>{{ row.item.supplier.nama }}</b>
                    </small>
                    <small class="d-block">
                        <b v-if="row.item.is_online === 1">{{ row.item.shop_name }}</b>
                        <b v-if="row.item.is_online === 2">{{ row.item.supplier.no_hp }}</b>
                    </small>
                </div>
            </template>
            <template v-slot:cell(total_item)="row">
                <div>{{ row.item.total_item }} {{ row.item.satuan || "Pcs" }}</div>
            </template>
            <template v-slot:cell(no_invoice)="row">
                <div>
                    <small><b>{{ row.item.no_invoice }}</b></small>
                    <small class="d-block"><b>{{ row.item.tanggal }}</b></small>
                </div>
            </template>
            <template v-slot:cell(total_dana)="row">
                <div class="d-flex justify-content-between">
                    <div>Rp.</div>
                    <span>{{ $helper.rupiah(row.item.total_dana || 0) }}</span>
                </div>
            </template>
        </b-table>

        <b-modal id="m-detail" size="lg" title="Detail Daftar Selisih" no-close-on-esc hide-footer>
            <ul class="list-group list-group-flush">
                <li v-for="item in itemSelected" :key="item.id" class="list-group-item p-1">
                    <div class="row">
                        <div class="col-md-8">
                            <div>{{ item.nama_barang }}</div>
                            <small>Jumlah Selisih : {{ item.total_item }} Pcs</small>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <h6 class="text-end">Rp {{ $helper.rupiah(item.total_dana || 0) }}</h6>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="d-flex pt-4 justify-content-end">
                <b-button @click="handleTerimaBarang" variant="pgiBtn">Terima</b-button>
            </div>
        </b-modal>
    </b-card>
</template>

<script>
import axiosClient from '../../../config/api'

export default {
    data() {
        return {
            isLoading: false,
            fields: [
                { key: "supplier", label: "Supplier" },
                { key: "no_invoice", label: "Invoice" },
                { key: "total_item", label: "Total Selisih" },
                { key: "total_dana", label: "Total Dana Selisih" },
                { key: "action", label: "Aksi" },
            ],
            listSelisih: [],
            itemSelected: []
        }
    },
    methods: {
        openModalDetail(value) {
            this.itemSelected = value.detail_refund
            this.$bvModal.show("m-detail")
        },
        async getDataKirimUlang() {
            try {
                this.isLoading = true
                const response = await axiosClient({
                    method: 'GET',
                    url: "transaksi/refund/barang",
                    params: {
                        order_by: 'DESC'
                    }
                })
                this.listSelisih = response.data.data
            } catch (error) {
                this.$helper.toastErr(error.message)
            }
            finally {
                this.isLoading = false
            }
        },
        async handleTerimaBarang() {
            try {
                const formTerima = this.itemSelected.map(item => {
                    return {
                        "id_refund": item.id,
                        "flag": 3,
                        "status": 2
                    }
                })

                const response = await axiosClient({
                    url: 'transaksi/refund/update-barang',
                    method: 'POST',
                    data: formTerima
                })
                if (response.status === 200) {
                    this.$bvModal.hide("m-detail")
                    this.$helper.toastSucc(this, "Sukses Terima Barang")
                    this.getDataKirimUlang()
                }

            } catch (error) {
                this.$helper.toastErr(this, error.response.data.message)
            }
        }

    },
    mounted() {
        this.getDataKirimUlang()
    }
}
</script>